<template>
  <v-card
    :key="i"
    flat
  >
    <v-card-title>
      <h2 class="text-h5">
        Sales Market Share Analysis
      </h2>
    </v-card-title>
    <v-card-text>
      <div
        v-if="loaded"
        id="chart"
      >
        <div style="background-color: #F2F2F2; padding: 20px; border-radius: 5px; margin-bottom: 20px;">
          <p style="font-size: 16px; line-height: 1.5; margin-bottom: 10px;">
            {{ rawData[0].aiReport }}
          </p>
        </div>
        <apex-chart
          :height="height*.3"
          type="line"
          :options="lineChartOptions"
          :series="updateSeriesWithColors(lineChartSeries)"
        />
        <apex-chart
          :height="height*.3"
          type="area"
          :options="stackedAreaChartOptions"
          :series="updateSeriesWithColors(stackedAreaChartOptions.series)"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { useWindowSize } from '@vueuse/core'
import { apiService } from '@/api'
import { ref, onMounted, watch } from 'vue'
import { currencyConvert, updateSeriesWithColors } from '@/util/functions'
import { kFormatter } from '@core/utils/filter'
import regression from 'regression'
import { getInstance } from '@/auth/authWrapper'

export default {
    name: 'SalesMarketShareAnalysis',
    setup() {
        const { width, height } = useWindowSize()
        const loaded = ref(false)
        const rawData = ref({})
        const i = ref(0)
        const data = ref(null)
        const lineChartOptions = ref([])
        const lineChartSeries = ref([])
        const stackedAreaChartOptions = ref([])
        const mfrArray = getInstance().user['https://api.pharmaguide.ca/claims/app_metadata'].mfr
        const resp = ref([{
            chartData: {
                SDZ: {
                    '2022-8': '44193.06',
                    '2022-9': '45090.65',
                    '2022-10': '46477.80',
                    '2022-11': '46514.13',
                    '2022-12': '47072.47',
                    '2023-1': '45635.05',
                    '2023-2': '39198.49',
                    '2023-3': '49875.28',
                    '2023-4': '39890.24',
                    '2023-5': '53094.05',
                    '2023-6': '59367.95',
                    '2023-7': '47795.15',
                    '2023-8': '60092.75'
                },
                otherSales: {
                    '2022-7': '89380.14',
                    '2022-8': '174778.28',
                    '2022-9': '187684.21',
                    '2022-10': '184888.71',
                    '2022-11': '208214.35',
                    '2022-12': '216050.55',
                    '2023-1': '241612.22',
                    '2023-2': '233154.07',
                    '2023-3': '268067.34',
                    '2023-4': '232518.90',
                    '2023-5': '256686.82',
                    '2023-6': '270748.72',
                    '2023-7': '216832.19',
                    '2023-8': '284533.17',
                    '2023-9': '163820.95'
                }
            },
            aiReport: "Based on the given data, it is clear that both SDZ and otherSales have been on a general upward trend for the months documented. SDZ saw its highest sales in August 2023 with a total of $60,092.75 and its lowest in February 2023 at $39,198.49. The year 2023 seems significantly productive for SDZ with six out of eight months registering sales above $45,000. \n\nFor 'otherSales', August 2023 was also the best month with sales totaling $284,533.17, which is significantly higher than SDZ's record. The lowest sales for 'otherSales' was recorded in July 2022, with $89,380.14. Month by month, 'otherSales' also show a trend of increasing sales figures, especially with four consecutive increases from September 2022 to January 2023. However, it's worth noting the significant drop in sales in September 2023 to $163,820.95, which is worth investigating further.\n\nIn comparison, 'otherSales' records significantly higher sales than SDZ in every corresponding month. The difference suggests that 'otherSales' might comprise a larger sales division or more popular products. Yet, the consistent growth in both areas indicates a successful overall sales strategy and an expanding customer base in all markets linked to these sales figures.",
            reportType: 'SalesMarketShareAnalysis',
            userId: 'auth0|6423ce1918496449c9cb01e9',
            timestamp: '2023-09-18T16:24:00.233Z',
            _id: '650879a00eb5bd191096b349'
        }])

        // const getSalesMarketShareAnalysis = async () => {
        //     try {
        //         return await apiService.getSalesMarketShareAnalysis()
        //     } catch (error) {
        //         console.log(error)
        //     }
        // }

        const getSalesMarketShareAnalysis = async () => {
            try {
                const res = await apiService.getAiReport()

                return res
            } catch (error) {
                console.log(error)
            }
        }

        onMounted(() => {
            getSalesMarketShareAnalysis().then(
                res => {
                    i.value += 1
                    rawData.value = res
                    data.value = res[0].chartData
                    loaded.value = true
                }
            )
        })

        watch(data, () => {
            if (data.value !== null) {
                // Transform the data into an array of objects
                const chartData = Object.keys(data.value[mfrArray[0]]).map(date => ({
                    date,
                    [mfrArray[0]]: parseFloat(data.value[mfrArray[0]][date]),
                    otherSales: parseFloat(data.value.otherSales[date]),
                }))

                // Calculate trend line using regression library
                const trendLineValues = regression.linear(chartData.map((datum, index) => [index, datum[mfrArray[0]]])).points.map(point => point[1])

                // Create the line chart options and series
                lineChartOptions.value = {
                    chart: {
                        group: 'SalesMarketShareAnalysis',
                        id: 'lineChart',
                        height: 400,
                        toolbar: {
                            show: true
                        }
                    },
                    forecastDataPoints: {
                        count: 0,
                        fillOpacity: 0.5,
                        strokeWidth: undefined,
                        dashArray: 4,
                    },
                    yaxis: {
                        labels: {
                            minWidth: 40,
                            formatter: value => currencyConvert(value),
                        }
                    },
                    xaxis: {
                        type: 'category',
                        categories: chartData.map(datum => datum.date),
                        tickAmount: 10
                    }
                }

                lineChartSeries.value = [
                    {
                        name: mfrArray[0],
                        data: chartData.map(datum => datum[mfrArray[0]])
                    },
                    {
                        name: 'Other Generics',
                        data: chartData.map(datum => datum.otherSales)
                    },
                    {
                        name: 'Trend Line',
                        data: trendLineValues,
                        type: 'line'
                    }
                ]

                // Create the stacked area chart options and series
                stackedAreaChartOptions.value = {
                    chart: {
                        group: 'SalesMarketShareAnalysis',
                        id: 'stackedAreaChart',
                        height: 400,
                        stacked: true,
                        toolbar: {
                            show: true
                        }
                    },
                    forecastDataPoints: {
                        count: 0,
                        fillOpacity: 0.5,
                        strokeWidth: undefined,
                        dashArray: 4,
                    },

                    yaxis: {
                        labels: {
                            minWidth: 40,
                            formatter: value => currencyConvert(value),
                        }
                    },
                    xaxis: {
                        type: 'category',
                        categories: chartData.map(datum => datum.date),
                        tickAmount: 10
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: [1, 1, 4]
                    },
                    fill: {
                        opacity: [0.2, 0.2, 1]
                    },
                    colors: ['#8f5aeb'],
                    series: [
                        {
                            name: mfrArray[0],
                            data: chartData.map(datum => datum[mfrArray[0]])
                        },
                        {
                            name: 'Other Generics',
                            data: chartData.map(datum => datum.otherSales)
                        },

                        // {
                        //     name: 'market share',
                        //     data: chartData.map(datum => (datum.TAR / (datum.TAR + datum.otherSales)) * 100)
                        // }
                    ],
                    labels: chartData.map(datum => datum.date)
                }
            }
        })

        return {
            mfrArray,
            i,
            data,
            resp,
            updateSeriesWithColors,
            kFormatter,
            currencyConvert,
            getSalesMarketShareAnalysis,
            loaded,
            rawData,
            width,
            height,
            lineChartOptions,
            lineChartSeries,
            stackedAreaChartOptions,
        }
    }
}
</script>
